import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './language/i18n'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'

import '@/styles/reset.css'

import 'lib-flexible/flexible'

const app = createApp(App)
app.config.productionTip = false

app.use(Antd)

app.use(store).use(router).use(i18n).mount('#app')

app.config.globalProperties.$cdn = 'https://assets-img.myazstore.com/medwory'
