<template>
  <div class="header">
    <router-link to="/">
      <img :src="$t('header.logoPic')" alt="" class="logo">
    </router-link>
    <a-space :size="64" class="rt_menus">
      <a-button type="link" :class="isSelected('story') ? 'btn btn_s' : 'btn'" @click="goPage('story')">Story</a-button>
      <a-button type="link" :class="isSelected('products') ? 'btn btn_s' : 'btn'" @click="goPage('products')">Products</a-button>
      <a-button type="link" :class="isSelected('farm') ? 'btn btn_s' : 'btn'" @click="goPage('farm')">Our Farm</a-button>
    </a-space>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const isSelected = (key) => {
  return route.path.indexOf(key) > -1
}

const goPage = (key) => {
  if (isSelected(key)) {
    return
  }
  router.push(`/${key}`)
}
</script>

<style lang="less" scoped>
@import "../../styles/font.css";

.header {
  display: flex;
  // padding: 0 12.5%;
  width: 100%;
}

.logo {
  height: 160px;
  display: block;
}

.rt_menus {
  margin: auto 0 auto auto;
}

.btn {
  color: #1370C3;
  font-family: Nunito-Regular;
  font-size: 26px;
}

.btn_s {
  font-family: Nunito-Black;
}
</style>
