<template>
  <div>
    <img :src="$t('home.topBanner')" alt="" class="full_img">
  </div>
  <div class="mid">
    <a-space direction="vertical" :size="40">
      <router-link to="/story">
        <img :src="$t('home.midBanner1')" alt="">
      </router-link>
      <router-link to="/farm">
        <img :src="$t('home.midBanner2')" alt="">
      </router-link>
      <router-link to="/products">
        <img :src="$t('home.midBanner3')" alt="">
      </router-link>
    </a-space>
  </div>
</template>

<script setup>
</script>

<style lang="less" scoped>
img {
  width: 100%;
  display: block;
}

.full_img {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
}

.mid {
  width: 100%;
  padding: 49px 0 100px 0;
  // background-image: url("https://assets-img.myazstore.com/medwory/bg.png");
  // background-size: 100% auto;
}

@media only screen and (max-width: 1440px) {
  .mid {
    padding-left: 24px;
    padding-right: 24px;
  }
}
</style>
