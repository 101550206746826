<template>
  <div class="footer_b">
    <img :src="$t('footer.footerPic')" alt="">
    <div class="footer_c">
      <div class="footer_lay">
        <a-row>
          <a-col :span="18">
            <a-space direction="vertical" :size="55">
              <div class="come_in">COME IN</div>
              <div>Level 12,440 Collins Street Melbourne VIC 3000  Australia</div>
              <div style="display: flex;">
                <div style="display: flex;">
                  <img src="../../assets/medowrylogo.png" alt="" class="s_logo">
                  <span style="margin: auto 0 auto 16px;">Medowry</span>
                </div>
                <div style="margin-left: auto; color: white; display: flex;">
                  <img src="../../assets/azglogo.png" alt="" class="s_logo">
                  <a href="https://myaz.com.au" target="_blank" style=" color: white;margin: auto 0 auto 16px; border-bottom: 2px solid #fff;">
                    https://myaz.com.au
                  </a>
                </div>
              </div>
            </a-space>
          </a-col>
          <a-col :span="4">
            <a-space direction="vertical" :size="55">
              <div class="come_in">QUICK CONTACT</div>
              <div>1300 965 539</div>
              <div>
                info@medowry.com.au
              </div>
            </a-space>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
  <!-- <div class="center240 footer">
    <div class="footer_item">{{ $t('footer.address') }}</div>
    <div class="footer_item">{{ $t('footer.email', { at: '@' }) }}</div>
    <div class="footer_item">{{ $t('footer.tel') }}</div>
  </div> -->
</template>

<script>
export default {
  name: 'PageFooter'
}
</script>

<style lang="less" scoped>
@import "../../styles/font.css";

.footer_b {
  position: relative;

  .footer_c {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;

    .footer_lay {
      max-width: 1666.67px;
      width: 100%;
      margin: auto;
      font-family: Nunito, Nunito-Black;
      color: white;
      font-size: 28px;
      padding: 8px;
      box-sizing: border-box;
    }

    .come_in {
      font-family: Nunito, Nunito-Regular;
    }

    .s_logo {
      width: 54px;
    }
  }
}
img {
  width: 100%;
  display: block;
}
// .footer {
//   height: 157px;
//   background: #252A36;
//   text-align: left;
//   display: flex;
//   flex-direction: column;
// }

// .footer_item {
//   margin: auto 0;
//   font-size: 22px;
//   color: rgba(255, 255, 255, 0.70);
// }
</style>
